export const resizeImage = (url, screenWidth) => {
  const urlBase =
    "https://img.paradigma.education/img/https://storage.googleapis.com/paradigma-dev-8d6b0.appspot.com/";

  url = typeof url === "string" ? url : String(url);

  if (url.startsWith(urlBase)) {
    return url;
  }

  if (
    url.startsWith(
      "https://img.paradigma.education/img/https://storage.googleapis.com/paradigma-firebase.appspot.com/",
    )
  ) {
    return url;
  }

  const getUrl = (url) => {
    if (url.startsWith(urlBase)) {
      return urlBase;
    }
    if (url.startsWith("https://assets.paradigma.education/")) {
      return "https://paradigma.education/cdn-cgi/image/quality=75/https://assets.paradigma.education/";
    }
    if (
      url.startsWith("https://lh") ||
      url.startsWith("https://lh6.googleusercontent.com/") ||
      url.startsWith("https://cdn-images-1.medium.com/")
    ) {
      return `https://paradigma.education/cdn-cgi/image/quality=75/`;
    }
    if (url.startsWith("https://paradigma.education/cdn-cgi/")) {
      return "https://paradigma.education/cdn-cgi/image/quality=75/https://storage.googleapis.com/paradigma-dev-8d6b0.appspot.com/";
    }
    if (
      url.startsWith(
        "https://img.paradigma.education/img/https://storage.googleapis.com/paradigma-firebase.appspot.com/",
      )
    ) {
      return "https://paradigma.education/cdn-cgi/image/quality=75/https://storage.googleapis.com/paradigma-firebase.appspot.com/";
    }
    if (
      url.startsWith(
        "https://firebasestorage.googleapis.com/v0/b/paradigma-dev-8d6b0.appspot.com/",
      )
    ) {
      return `https://paradigma.education/cdn-cgi/image/quality=75/https://storage.googleapis.com/paradigma-dev-8d6b0.appspot.com/`;
    }
    if (
      url.startsWith(
        "https://firebasestorage.googleapis.com/v0/b/paradigma-firebase.appspot.com/",
      )
    ) {
      return `https://paradigma.education/cdn-cgi/image/quality=75/https://storage.googleapis.com/paradigma-firebase.appspot.com/`;
    }

    return `https://paradigma.education/cdn-cgi/image/quality=75/https://origin.videostreaming.technology/paradigmaeducation/`;
  };

  let newUrlAsis;
  let result;

  switch (true) {
    case /flame.*\.(png|gif|jpe?g)/g.test(url):
      result = url.match(/flame.*\.(png|gif|jpe?g)/g)[0];
      newUrlAsis = getUrl(url) + result;
      break;
    case /badges[^?]+\?/.test(url):
      result = url.match(/badges[^?]+\?/)[0];
      newUrlAsis = getUrl(url) + result.slice(0, -1);
      break;
    case /badges.*\.(png|gif|jpe?g)/g.test(url):
      result = url.match(/badges.*\.(png|gif|jpe?g)/g)[0];
      newUrlAsis = getUrl(url) + result;
      break;
    case /portraitThumbnail.*$/g.test(url):
      result = url.match(/portraitThumbnail.*$/g)[0];
      newUrlAsis = getUrl(url) + result;
      break;
    case /thumbnails[^?]+\?/.test(url):
      result = url.match(/thumbnails[^?]+\?/)[0];
      newUrlAsis = getUrl(url) + result.slice(0, -1);
      break;
    case /thumbnails.*$/g.test(url):
      result = url.match(/thumbnails.*$/g)[0];
      newUrlAsis = getUrl(url) + result;
      break;
    case /thumbnail.*$/g.test(url):
      result = url.match(/thumbnail.*$/g)[0];
      newUrlAsis = getUrl(url) + result;
      break;
    case /data:image\/[a-zA-Z]*;base64,[^\s]+/.test(url):
      newUrlAsis = url;
      break;
    default:
      newUrlAsis = getUrl(url) + url;
  }

  return newUrlAsis;
};
